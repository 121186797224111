import Navbar from "./pages/Navbar"
import Navextension from "./pages/Navextension"
import Home from "./pages/Home"
import About_Us from "./pages/About_Us"
import Our_Mission from "./pages/Our_Mission"
import Our_Projects from "./pages/Our_Projects"
import IT from "./pages/IT"
import Devices from "./pages/Devices"
import Web_Dev from "./pages/Web_Dev"
import { Route, Routes } from "react-router-dom"

function App() {
  return <>
    <Navbar />
  
    <div className="container">
      <Routes>
        <Route path="/" element= {<Home />} />
        <Route path="/About_Us" element= {<About_Us />} />
        <Route path="/Our_Mission" element= {<Our_Mission />} />
        <Route path="/Our_Projects" element= {<Our_Projects />} />
        <Route path="/IT" element={<IT />} />
        <Route path="/Devices" element={<Devices />} />
        <Route path="/Web_Dev" element={<Web_Dev />} />
      </Routes>
    </div>
  </>
}

export default App;
