import "./css/footer.css"
import logo from "./images/HBIndustryLogo.png"

export default function Footer () {
	return <div>
<section class="foot">
<section class="eclectic">
<section class="eclecticinfo">
    <h2></h2>
    <h2></h2>
    <h2></h2>
</section>
</section>
<section class="footdesc">
    <section class="center">
        <h1><b>Contact</b></h1>
        <h2><b></b> username</h2>
        <h2><a href="mailto: packdkitchenllc@gmail.com?">Email Me</a></h2>
    </section>
    <section class="center">
        <h1><b>Social</b></h1>
        <h2><b>Facebook</b> username</h2>
        <h2><b>Instagram</b> username</h2>
    </section>
    <section class="center">
        <h1><b>Associates Links</b></h1>
        <h2>Dani K =<a href="danikgymandwellness.com">Dani K Gym</a></h2>
        <h2>Site created by <a href="hbindustry.co">HBIndustry</a></h2>
    </section>
    <section class="center">
        <h1><b>Resources</b></h1>
        <h2></h2>
        <h2>Graphics Created by <a href="hbindustry.co">HBIndustry</a></h2>
    </section>
</section>
</section>
</div>
}

