import Slider from "./Slider"
import Footer from "./Footer"
import background from "./images/HBIndustry_Background.png"
import "./css/home.css"

import React, { useState } from 'react';

export default function Home() {
    const [showSubScreen, setShowSubScreen] = useState(false); // react hook to manage state

    const handleClick = () => { // event handler called 'handleClick' opens subscreen
        setShowSubScreen(true); // updates the value of showSubScreen to true -> triggering a re-rendering of the component
    };

    const handleCloseSubScreen = () => { // event handler called 'handleCloseSubScreen' closes subscreen
        setShowSubScreen(false); 
    };


    return <div>
        <section class="frontpage" style={{
            backgroundImage: 'url(' + background + ') ', 
           }}>
            <section class ="aboutUsDescbin">
                <section class ="aboutUsBox">
                     <h2>About Us</h2>
                </section>

            </section>
            <section>
            <Slider/>
        </section>

       
            <section class="homeInfo">
                <h2> Want More Info?</h2>
            </section>
            
            <Footer/>
               
          </section>
    
         </div>
}