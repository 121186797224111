import "./css/devices.css"
import Disp from "./images/dispologo.png"
import DaniK from "./images/DaniKLogo.png"
import ETC from "./images/EclecticTireCompanyLogo.png"
import Devicepic from "./images/Devicepic.png"
import * as React from 'react';
import Button from '@mui/material/Button';

export default function Devices () {

	return <div className="webpage">
			<section class ="webpage_header1">
				<h1>Devices</h1>
			</section>

		<section class = "webpage_header2">
				<h2></h2>
				<section class ="desc">
				</section>
		</section>
		<section class = "descbin" >
		<section ><img src={Devicepic} width="999" height="500"></img> </section> 
			<section  class = "descbin2"  >
            <h3>We are here not just for big businesses, but anyone looking for help with their devices. That's why; at HBIndustry, we provide device maintenance for all individuals looking for help. If you need help setting up a computer, network, or just need to learn, we are here to help you.</h3>
			</section>
			</section>
<section class="web_dev_header2">
	<h2></h2>
</section>
	<section class = "work_done">
		<section class = "row">
		<section class="column">
				<img src={Disp} width="300" height="300"></img>
				</section>
				<section class="column">
				<img src={ETC} width="300" height="300"></img>
				</section>
				<section class="column">
					<img src={DaniK} width="300" height="300"></img>
				</section>

		</section>
</section>

<section class="web_dev_header2"> Look for a Quote?

</section>
        <Button variant="contained" color="success" href="#contained-buttons">
		<a href="javascript:void( window.open( 'https://form.jotform.com/233135107142039', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) "> Device Maintenance </a>
    </Button>

    </div>
}

 