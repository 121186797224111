import { Link, useMatch, useResolvedPath} from "react-router-dom"
import Navextension from "./Navextension"
import logo from "./images/HBIndustryLogo.png"
import {AiOutlineMenu,AiOutlineClose}
from "react-icons/ai"
import "./css/nav.css"
import {useState,} from "react"

export default function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setNav(false);
    const [nav,setNav] = useState(false);

    return (
     <section className="topofscreen">  
    <nav className="nav">
        
        <Link to="/"className="site-title">
        <img src={logo} ></img>
        </Link>
        <nav className={nav ? "menu" :"active" } >
        <ul className="menu">
            <CustomLink to="/About_Us" onClick={closeMobileMenu}>About Us</CustomLink>
            <CustomLink to="/Our_Mission"onClick={closeMobileMenu}>Our Mission</CustomLink>
            <CustomLink to="/Our_Projects"onClick={closeMobileMenu}>Our Projects</CustomLink>
       
        </ul>
        </nav>
    </nav>
    <div onClick={()=> setNav(!nav)} className="mobileBtn">
          {nav ? <AiOutlineClose size={25}/> : <AiOutlineMenu size={25} />}
         
  
        </div>

    <Navextension />
    </section>  
  )
}


function CustomLink({ to, children, ...props}) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
   
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}