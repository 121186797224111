import "./css/home.css"

export default function Our_Projects () {
	return <div>
		<section class ="webpage_header1">
					<h1> Our Projects </h1>
				</section>
				<section class = "webpage_header2">
					<h2>Work Done</h2>
				</section>
				<section class="descbin3">
				<section class descbin1>	<h3>Currently all our projects are for private clients. We hope to start more open-source public projects in 2024!</h3>
				</section>
				</section>
		<h2>test</h2>
	</div>
}
