import "./css/ourMission.css"
import logo from "./images/logo.png"

export default function Our_Mission () {
	return <div>
		<section class ="webpage_header1">
					<h1>Our Mission </h1>
				</section>
        <section class="descbin3">
          <section class ="row">
            <section class ="column2">
              <h3>3 Year Plan!</h3>  
                         <section class="threeYear">
                          <h3>Over the next 3 years we are planning to increase our server infrastructure,
                            <br></br>
                             so that all our clients can benefit from the increased speeds and reliability.
                             <br></br>
                              During this same time we hope to begin so long term projects to help our local communities.
                              <br></br>
                               These projects will specifically help non-profit groups “Autism Speaks”,
                              <br></br> 
                               to help unserved groups with their technology needs. </h3>
              </section>
              </section>
              <section class= "column2">
                <h3>5 Year Plan!</h3>
                <section class = " fiveyear">
                <h3>We hope to continue to expand our infrastructure to help other clients in Pennsylvania, West Virginia and Virginia and Delaware. We also hope to begin production of our software suite to help small businesses to medium sized businesses. This software suite aims to help in all aspects of commerce from marketing to employment.</h3>
                </section>
              </section>
            </section>
          </section>
        
				</div>
}