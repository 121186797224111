import "./css/webDev.css"
import AAE from "./images/AAE.png"
import ETC from "./images/EclecticTireCompanyLogo.png"
import Disp from "./images/dispologo.png"
import DaniK from "./images/DaniKLogo.png"
import WebDev from "./images/WebDev.png"
import PackD from "./images/logo.png"
import * as React from 'react';
import Button from '@mui/material/Button';

export default function Web_Dev () {

return <div className="webpage">
			<section class ="webpage_header1">
				<h1> Web Developement</h1>
			</section>

		<section class = "webpage_header2">
				<h2>Our Work</h2>
				<section class ="desc">
				</section>
		</section>
		<section class = "descbin " >
			<section> <img src={WebDev} width="999"height="899"></img>				
			</section>

			<section  class = "descbin2"  >
			<h3>As a web development team we are here to complete any and all web projects. Whether you are a small company just starting, or a large competitor looking to expand your web presents, we are here to help!!! We have seen a number of different types of projects including inventory systems, web shops, user account set-up, email automation, form automation, shipping automation, etc. At HBIndustry we are also dedicated to keep the most up to date and controllable infrastructure. This is why we host our servers in house, which allows us to offer you the best prices in the industry with the absolute best service!!!</h3>
			
			</section> 
			</section>
<section class="web_dev_header2">
	<h2>Who we work for</h2>
</section>
	<section class = "work_done">
	<section class= "row">
				<section class="column">
				<img src={PackD} width="300" height="300"></img>
				</section>
				<section class="column">
				<img src={AAE}  width="300" height="300"></img>
				</section>
				<section class="column">
				<img src={ETC} width="300" height="300"></img>
				</section>


				</section>			
</section>

<section class="web_dev_header2">
	<h3> Looking for a Quote?</h3>

</section>
        <Button variant="contained" color="success" href="#contained-buttons">
		<a href="javascript:void( window.open( 'https://form.jotform.com/233517385462056', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) "><h3> Get a Quote</h3> </a>
</Button>

	</div>
}