import "./css/aboutUs.css"
import logo from "./images/HBIndustryLogo.png"
export default function About_Us () {
	return <div>
		<section class ="webpage_header1">
					<h1> About Us </h1>
				</section>
				<section class = "webpage_header2">
					<h2>Meet The Team</h2>
				</section>
<section claaName = "nios">
  <div class="row">
  <div class="column3">
    <div class="card3">
      <img src={logo}alt="logo" width="300"/>
      <div class="container3">
        <h3>John L.</h3>
        <p class="title3">Owner & Founder</p>
        <p>	John is the founder of HBIndustry and an Entrepreneur owning multiple businesses. He has great experience in IT especially when it comes to Physical Device Maintenance, Sever Deployment, and Graphic Design. As a Stevenson University graduate he strides himself on focusing on the finer details, which is also a founding principal of HBIndustry. Please feel free to reach out and ask him any questions related to business or IT, he would be more than happy to give some time. </p>
        <p><button class="button3"><a href="mailto:JohnLindemon@HBIndustry.co">Send Email</a></button></p>
      </div>
    </div>
  </div>
  <div class="column3">
    <div class="card3">
      <img></img>
      <div class="container3">
        <h3>Nate L.</h3>
        <p class="title3"></p>
        <p>Hi my name is Nate Lance and I am a graduate from Towson university. I earned a B.S. in Information Systems at Towson University and have been surrounded by technology all throughout my life. I also have experience in IT networking and with multiple Computer Science coding and markup languages as well. In attempts to make myself a more well rounded technology professional I also expose myself to graphic design tasks. I enjoy using technology to improve everyday tasks and learn more about the technology industry as a whole.</p>
        <p><button class="button3"><a href="mailto:nate@HBIndustry.co">Send Email</a></button></p>
      </div>
    </div>
  </div>

  <div class="column3">
    <div class="card3">
      <img></img>
      <div class="container3">
        <h3>Aaron R.</h3>
        <p class="title3"></p>
        <p>Hello, I am a tech enthusiast with over 5yrs experience with the following technologies:installs and  device refurbishment, competent in several programing languages, server implementation and management, web or App development and much more.I am a Carroll Community College alumni with a educational background consisting of an AAS in Cybersecurity, and a Network security certification. Currently, I am pursing my CompTIA A+ certification. It is important for me to help people use technology securely. I look forward to assisting you in discovering how beneficial technology can be when done right.</p>
        <p><button class="button3"><a href="mailto:aaron@HBIndustry.co">Send Email</a></button></p>
      </div>
    </div>
	  </div>
    </div>
          </section>
			<section class = "pagefull1">
				<section class="descbin3">
					<h3>
					HBindustry is a collective of many different backgrounds, educations, and experiences. We have experts in security, web development, IT, and automation. This includes specific expertise with systems such as servers, inventory management systems, security systems and device installation. All our techs have educations from a range of different esteemed Maryland Universities including Stevensons, Towson, Carroll and have completed several dedicated programs like cisco networking academy, Genetic cert course, axis certification course, and CompTIA certifications. As a company we have had several years of experience helping a number of small to medium sized businesses with a range of these tech services and we hope to continue to serve companies like yours as we grow.
					</h3>

				</section>
			</section>
	</div>
}
