import "./css/home.css"
import Disp from "./images/dispologo.png"
import DaniK from "./images/DaniKLogo.png"
import ETC from "./images/EclecticTireCompanyLogo.png"
import ITpic from "./images/IT.png"
import * as React from 'react';
import Button from '@mui/material/Button';

export default function IT () {
	return <div class="webpage">
			<section class ="webpage_header1">
				<h1> IT </h1>
			</section>

		<section class = "webpage_header2">
				<h2>Our Work</h2>
				<section class ="desc">
				</section>
				
		</section>
		
		<section class = "descbin" >
		<section ><img src={ITpic} width="999" height="499"></img> </section> 
			
			<section  class = "descbin2"  >
				
<h3>We offer a range of different IT services for business that are sure to bring your company the newest tech innovations. Not only do we set-up, test, and manage networks, but we also do device management, physical security, camera installs, server installation, work with tech vendors, and handle all your related tech projects. We, unlike other companies, are a one stop shop and can surely replace your IT department. Also at HBIndustry, we have a dedicated in-house team here to answer any and all your related tech questions. Don’t forget, for unmatched service make sure to check out HBIndustry!!! </h3>

			</section> 
			</section>
<section class="web_dev_header2">
	<h2></h2>
</section>
	<section class = "work_done">
		<section class ="row">
		<section class="column">
				<img src={Disp} width="300" height="300"></img>
				</section>
				<section class="column">
				<img src={ETC} width="300" height="300"></img>
				</section>
				<section class="column">
					<img src={DaniK} width="300" height="300"></img>
				</section>


		</section>
</section>

<section class="web_dev_header2"> Look for a Quote?

</section>
        <Button variant="contained" color="success" href="#contained-buttons">
		<a href="javascript:void( window.open( 'https://form.jotform.com/233527833679065', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) "> IT Work </a>
</Button>
		
			</div>
}